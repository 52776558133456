@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    @font-face {
        font-family: 'Manrope';
        src:
            url('../public/fonts/manrope/Manrope-ExtraBold.otf') format('opentype'),
            url('../public/fonts/manrope/Manrope-ExtraBold.woff2') format('woff2'),
            url('../public/fonts/manrope/Manrope-ExtraBold.ttf') format('truetype');
        font-weight: 800;
        font-display: swap;
    }

    @font-face {
        font-family: 'Manrope';
        src:
            url('../public/fonts/manrope/Manrope-Bold.otf') format('opentype'),
            url('../public/fonts/manrope/Manrope-Bold.woff2') format('woff2'),
            url('../public/fonts/manrope/Manrope-Bold.ttf') format('truetype');
        font-weight: bold;
        font-display: swap;
    }

    @font-face {
        font-family: 'Manrope';
        src:
            url('../public/fonts/manrope/Manrope-Regular.otf') format('opentype'),
            url('../public/fonts/manrope/Manrope-Regular.woff2') format('woff2'),
            url('../public/fonts/manrope/Manrope-Regular.ttf') format('truetype');
        font-weight: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Manrope';
        src:
            url('../public/fonts/manrope/Manrope-SemiBold.otf') format('opentype'),
            url('../public/fonts/manrope/Manrope-SemiBold.woff2') format('woff2'),
            url('../public/fonts/manrope/Manrope-SemiBold.ttf') format('truetype');
        font-weight: 600;
        font-display: swap;
    }

    @font-face {
        font-family: 'Manrope';
        src:
            url('../public/fonts/manrope/Manrope-Light.otf') format('opentype'),
            url('../public/fonts/manrope/Manrope-Light.woff2') format('woff2'),
            url('../public/fonts/manrope/Manrope-Light.ttf') format('truetype');
        font-weight: 300;
        font-display: swap;
    }

    @font-face {
        font-family: 'Manrope';
        src:
            url('../public/fonts/manrope/Manrope-SemiBold.otf') format('opentype'),
            url('../public/fonts/manrope/Manrope-SemiBold.woff2') format('woff2'),
            url('../public/fonts/manrope/Manrope-SemiBold.ttf') format('truetype');
        font-weight: 600;
        font-display: swap;
    }

    /* :root {
        --primary-color: #fff;
        --secondary-color: #181d24;
        --secondary-bg: #b8c7e1;
        --shadow: 214, 229, 245;
    }
    @media (prefers-color-scheme: dark) {
    ? Uncommenting this will control the color scheme of the app. 
    ! The Scheme Needs More Work
    ? Move ⬇ root here
    }
*/
    :root {
        --primary-color: #1a1f26;
        --secondary-color: #e8e8e8;
        --border-color: #515674;
        --secondary-bg: #2a2f36;
        --shadow: 64, 68, 74;
        --navy: #08103a;
        --navy-solid-5: #eeeef2;
        --navy-solid-10: #e4e4e8;
        --navy-solid-15: #d7d9de;
        --navy-solid-30: #b1b5c1;
        --navy-solid-50: #81859b;
        --navy-solid-70: #515674;
        --risd-blue: #345dee;
        --polaris-green: #6cffa7;
        --card-gradient: linear-gradient(
            113deg,
            rgba(249, 249, 249, 0.5) 0%,
            rgba(240, 234, 243, 0.5) 100%
        );
        --shadow-color: 'rgba(10, 12, 15, 0.05)';
    }
}

@layer components {
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
        .glass-effect {
            /* From https://css.glass */
            background: rgba(255, 255, 255, 0.08);
            box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
            backdrop-filter: blur(5.5px);
            -webkit-backdrop-filter: blur(5.5px);
        }
    }

    /* fallback for Firefox (not supporting backdrop-filter) */
    @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
        .glass-effect {
            background-color: rgba(var(--shadow), 0.6) !important;
        }
    }
}

html,
body,
#__next {
    font-family: Manrope, system-ui, sans-serif;
    background-color: var(--primary-color);
    color: var(--secondary-color);
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
}

/* Tailwindcss pre-flight interferes with Mantine button components visibility.
In order to fix that we need to disabling pre-flight in the config, which leads to
an additional 8px margin on the body. This is a hack to offset that margin */
body,
main {
    padding: 0;
    margin: 0;
}

*,
::before,
::after {
    border-width: 0;
    border-style: solid;
    /* border-color:rgba(255, 255, 255, 0.3); */
}

* {
    box-sizing: border-box;
}

@keyframes terminal {
    from {
        transform: translate(100%);
    }

    to {
        transform: translate(-100%);
    }
}

@keyframes globeDotLayer {
    from {
        width: 0px;
    }

    50% {
        width: 100px;
    }

    to {
        width: 0px;
    }
}

@keyframes eventCard {
    from {
        transform: translate(500px);
    }

    33% {
        transform: translate(0px);
    }

    85% {
        transform: translate(0px);
    }

    to {
        transform: translate(0px, 200px);
    }
}

.hideScroll::-webkit-scrollbar {
    display: none;
}

.hideScroll {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.mapbox-logo {
    display: none;
}

.mapboxgl-ctrl-logo {
    display: none !important;
}

.mapbox-improve-map {
    display: none;
}

.mapboxgl-ctrl-compass {
    display: none;
}

.helm-scrollbar ::-webkit-scrollbar-track,
.helm-scrollbar ::-webkit-scrollbar,
.helm-scrollbar ::-webkit-scrollbar-thumb {
    width: 0;
    background-color: transparent;
}

.inner-shadow-bottom::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 60px;
    background-image: linear-gradient(to top, #e4e4e8, transparent);
    pointer-events: none;
}

.shadow-left {
    box-shadow: -8px 0px 10px rgba(0, 0, 0, 0.1);
}

.shadow-right {
    box-shadow: 8px 0px 10px rgba(0, 0, 0, 0.1);
}

.gradient-fade {
    position: relative;
}

.gradient-fade::after {
    content: '';
    position: absolute;
    top: 85%;
    left: 0;
    height: 15%;
    width: 100%;
    background: linear-gradient(0deg, #f8f6fa 0%, rgba(248, 246, 250, 0) 100%);
    pointer-events: none;
    /* Allow clicks, scrolls, etc. to pass through */
}

.no-scrollbar::-webkit-scrollbar {
    display: none;
}

/* Hide the scrollbar for Internet Explorer, Edge and Firefox */
.no-scrollbar {
    -ms-overflow-style: none;
    /* Internet Explorer and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.helm-card-style {
    border-radius: 8px;
    border-top: 0.5px solid rgba(0, 0, 0, 0.1);
    border-left: 0.5px solid rgba(0, 0, 0, 0.1);
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)),
        linear-gradient(112.87deg, rgba(249, 249, 249, 0.5) 0%, rgba(240, 234, 243, 0.5) 100%);
    box-shadow: 8px 8px 16px rgba(10, 12, 15, 0.05);
    backdrop-filter: blur(8px);
}
